@import url('https://fonts.cdnfonts.com/css/poppins');

.embla_slide_content {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 20px 40px;
}

.content_info .content_info_top {
  display: flex;
  align-items: center;
  gap: 5px;
}

.content_info_top .type {
  color: #f5f5f5;
  font-family: 'Poppins', sans-serif;
}

.content_info .content_info_top .year::before {
  content: '° ';
}

.content_info .content_info_top .year {
  color: #f5f5f58a;
  font-family: 'Poppins', sans-serif;
}

.content_info h1 {
  font-size: 30pt;
  font-family: 'Poppins', sans-serif;
}

.content_info .content_info_description {
  font-size: 10pt;
  max-width: 50rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  color: #f5f5f58a;
}

.content_buttons .content_buttons_btn {
  padding: 10px 50px;
  border: 0.5px solid #ff007f;
  background-color: transparent;
  color: #ff007f;
  cursor: pointer;
  font-size: 12pt;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  transition: all 0.2s ease-in-out;
}

.content_buttons .content_buttons_btn:hover {
  background-color: #ff007f;
  color: #f5f5f5;
}

@media (max-width: 575.98px) {
  .embla_slide_content {
    display: flex;
    padding: 10px 20px;
  }

  .content_info h1 {
    font-size: 18pt;
    width: 100%;
  }

  .content_info .content_info_description {
    font-size: 5pt;
    max-width: 40rem;

  }

  .content_info .content_info_top {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .content_buttons .content_buttons_btn {
    padding: 5px 25px;
    font-size: 12pt;
  }
}
