@import url('https://fonts.cdnfonts.com/css/poppins');

.tab_container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.tab_container p {
  padding: 20px;
  margin-left: 20px;
  font-family: 'Poppins', sans-serif;
  font-size: 15pt;
  border-left: 2px solid #ff007f;
}

.tabs {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 20px;
  margin-bottom: 20px;
}

.tabs .tablist {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  width: fit-content;
  background-color: #282828;
  padding: 10px;
  border-radius: 50px;
}

.tabs .tablist .tab {
  background-color: transparent;
  font-family: 'Poppins', sans-serif;
  padding: 10px 20px;
  cursor: pointer;
  color: #f5f5f5;
  border-radius: 30px;
  border-style: none;
  font-size: 20pt;
}

.tabs .tablist .tab.selected {
  background-color: #ff007f;
}

.tabs .tabPanels .tab_panels .time {
  display: flex;
  align-items: center;
}

.tabs .tabPanels .tab_panels .time p {
  border-left: none;
}

.tabs .tabPanels .tab_panels .time h3 {
  font-family: 'Poppins', sans-serif;
  width: 100%;
}

@media (max-width: 575.98px) {
  .tab_container {
    gap: 5px;
  }

  .tab_container p {
    font-size: 10pt;
    padding: 10px;
  }
  .tabs {
    padding: 0 10px;
  }
  .tabs .tablist {
    gap: 2px;
  }

  .tabs .tablist .tab {
    padding: 5px 10px;
    font-size: 10pt;
  }

  .tabs .tabPanels .tab_panels .time h3{
    font-size: 10pt;
  }
}
