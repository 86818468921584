@import url('https://fonts.cdnfonts.com/css/poppins');

.anime_container {
}

.anime_container_info {
}

.anime_container_info .anime_cover {
  height: 30rem;
  background-position: top;
  background-size: cover;
  display: flex;
  align-items: flex-end;
}

.anime_info {
  display: flex;
  align-items: flex-end;
  gap: 20px;
  font-family: 'Poppins', sans-serif;
  min-width: 1200px;
  margin: 0 auto;
}

.anime_info .anime_info_image_container .anime_info_image {
  height: 280px;
  width: 200px;
  border-radius: 5px;
  background-position: center;
  background-size: cover;
}

.anime_info .anime_info_infos {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.anime_info_titles {
  line-height: 35px;
}

.anime_info_titles h4 {
  font-weight: 200;
}

.anime_info_titles h1 {
  font-size: 30pt;
}

.anime_info p::before {
  content: '° ';
}

.anime_info p {
  font-size: 9pt;
  margin: 10px 0;
  font-weight: 200;
}

.complete {
  color: greenyellow;
}

.ongoing {
  color: #ff0080;
}
.anime_info .anime_info_infos .anime_info_genres {
  display: flex;
  align-items: center;
  gap: 5px;
}

.anime_info .anime_info_infos .anime_info_genres p {
  padding: 0 5px;
  background-color: #ff00806e;
  color: #f5f5f5;
  border-radius: 50px;
  font-size: 9pt;
  font-weight: 200;
  border: 1px solid #ff0080;
}

.anime_container_episodes {
  max-width: 1200px;
  margin: 50px auto;
}

.tabs {
  margin: 0;
  padding: 0;
}

.anime_container_episodes .tabs .tablist {
  background-color: inherit;
  border-bottom: 1px solid #f5f5f56c;
  padding: 0;
  border-radius: 0;
}

.anime_container_episodes .tabs .tablist .tab {
  font-size: 12pt;
  padding: 0 10px;
  font-family: 'Poppins', sans-serif;
}

.anime_container_episodes .tabs .tablist .tab.selected {
  padding: 10px 0;
  background-color: inherit;
  border-bottom: 2px solid #ff0080;
  border-radius: 0;
}

.anime_container_episodes .tabs .tabpanels .tabpanel {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  gap: 10px;
}

.anime_container_episodes .tabs .tabpanels .tabpanel .episode {
  height: 50px;
  width: 280px;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: flex-start;
  font-family: 'Poppins', sans-serif;
  font-size: 12pt;
  padding: 10px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.anime_container_episodes .tabs .tabpanels .tabpanel .episode:hover {
  border: 2px solid #ff0080;
  color: #ff0080;
  height: 50px;
  width: 290px;
  border-radius: 0;
}

.anime_container_episodes .tabs .tabpanels .tabpanel .episode p {
  font-weight: 200;
}

.about_this {
  margin: 10px 0;
}

.about_this .about {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: 'Poppins', sans-serif;
  max-width: 400px;
}

.about_this .about h3::after {
  content: '........................';
  font-weight: 100;
}

.about_this .about h4 {
  font-weight: 300;
}

.about_this .about h4::before {
  content: '........................';
  font-weight: 100;
}

.tab_characters {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  gap: 50px;
}

.characters {
  display: flex;
  gap: 50px;
  background-color: #292929;
  padding: 20px;
  border-radius: 5px;
}

.characters .character_anime {
  display: flex;
  gap: 10px;
}

.characters .character_anime .character_anime_image {
  height: 100px;
  width: 100px;
  background-position: center;
  background-size: cover;
  border-radius: 50px;
}

.characters .character_anime .character_anime_info {
  font-family: 'Poppins', sans-serif;
}

.characters .character_voice {
  display: flex;
  gap: 10px;
}

.characters .character_voice .character_voice_info {
  font-family: 'Poppins', sans-serif;
}

.characters .character_voice .character_voice_image {
  height: 100px;
  width: 100px;
  background-position: center;
  background-size: cover;
  border-radius: 50px;
}

.artwork_container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  gap: 20px;
  max-width: 100%; /* or any specific width you want for the container */
  height: auto; /* Adjusts height automatically */
  overflow: hidden; /* Ensures that any overflow is hidden */
}

.artwork_container .artwork_image {
  background-size: cover;
  background-position: center;
  height: 500px;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 5px;
}

.artwork_container p {
  font-family: 'Poppins', sans-serif;
  font-size: 12pt;
  color: red;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.loading_container {
  height: 50rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 575.98px),
  (-webkit-mindevice-pixel-ratio: 3),
  (min-resolution: 300dpi) {
  .anime_info {
    flex-direction: column;
    align-items: flex-start;
    min-width: 100%;
  }

  .anime_info .anime_info_image_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .anime_info .anime_info_image_container .anime_info_image {
    height: 220px;
    width: 160px;
  }

  .anime_info .anime_info_infos {
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .anime_info .anime_info_infos .anime_info_titles {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .anime_info .anime_info_infos .anime_info_titles h1 {
    font-size: 12pt;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .anime_info .anime_info_infos .anime_info_titles h4 {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .anime_container_episodes {
    margin: 20px auto;
  }

  .anime_container_episodes .tabs .tablist {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .anime_container_episodes .tabs .tabpanels .tabpanel {
    margin: 0 auto;
    justify-content: center;
  }

  .anime_container_episodes .tabs .tabpanels .tabpanel .episode {
    height: 50px;
    width: 180px;
  }
  .anime_container_episodes .tabs .tabpanels .tabpanel .episode:hover {
    height: 50px;
    width: 182px;
  }

  .mobile_para {
    padding: 10px;
  }

  .about_this {
    padding: 10px;
  }

  .tab_characters {
    padding: 0 20px;
  }

  .characters {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .characters .character_anime .character_anime_image {
    height: 50px;
    width: 50px;
  }

  .characters .character_anime .character_anime_info p {
    font-size: 8pt;
  }

  .characters .character_anime .character_anime_info h1 {
    font-size: 8pt;
  }

  .characters .character_voice .character_voice_image {
    height: 50px;
    width: 50px;
  }

  .characters .character_voice .character_voice_info p {
    font-size: 8pt;
  }

  .characters .character_voice .character_voice_info h1 {
    font-size: 8pt;
  }

  .artwork_container .artwork_image {
    height: 200px;
  }

  .artwork_container p {
    font-family: 'Poppins', sans-serif;
    font-size: 8pt;
    color: red;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (min-width: 768px) and (-webkit-mindevice-pixel-ratio: 3),
  (min-resolution: 300dpi) {
  .anime_info {
    flex-direction: column;
    align-items: flex-start;
    min-width: 100%;
  }

  .anime_info .anime_info_image_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .anime_info .anime_info_image_container .anime_info_image {
    height: 220px;
    width: 160px;
  }

  .anime_info .anime_info_infos {
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .anime_info .anime_info_infos .anime_info_titles {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .anime_info .anime_info_infos .anime_info_titles h1 {
    font-size: 12pt;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .anime_info .anime_info_infos .anime_info_titles h4 {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .anime_container_episodes {
    margin: 20px auto;
  }

  .anime_container_episodes .tabs .tablist {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .anime_container_episodes .tabs .tabpanels .tabpanel {
    margin: 0 auto;
  }

  .anime_container_episodes .tabs .tabpanels .tabpanel .episode {
    height: 50px;
    width: 180px;
  }
  .anime_container_episodes .tabs .tabpanels .tabpanel .episode:hover {
    height: 50px;
    width: 182px;
  }

  .mobile_para {
    padding: 10px;
  }

  .about_this {
    padding: 10px;
  }

  .tab_characters {
    padding: 0 20px;
  }

  .characters {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .characters .character_anime .character_anime_image {
    height: 50px;
    width: 50px;
  }

  .characters .character_anime .character_anime_info p {
    font-size: 8pt;
  }

  .characters .character_anime .character_anime_info h1 {
    font-size: 8pt;
  }

  .characters .character_voice .character_voice_image {
    height: 50px;
    width: 50px;
  }

  .characters .character_voice .character_voice_info p {
    font-size: 8pt;
  }

  .characters .character_voice .character_voice_info h1 {
    font-size: 8pt;
  }

  .artwork_container .artwork_image {
    height: 200px;
  }

  .artwork_container p {
    font-family: 'Poppins', sans-serif;
    font-size: 8pt;
    color: red;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
