@import url('https://fonts.cdnfonts.com/css/poppins');

.upcoming_anime_container {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.upcoming_anime_container .upcoming_anime_content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  align-content: stretch;
  width: 100%;
  gap: 10px;
  padding: 100px 20px 5px;
}

.upcoming_anime_container .upcoming_anime {
  height: 250px;
  width: 180px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 5px;
  display: flex;
  align-items: flex-end;
  padding: 10px;
}

.upcoming_anime_container .upcoming_anime .upcoming_anime_info .confirmed {
  font-family: 'Poppins', sans-serif;
  font-weight: 200;
  font-size: 9pt;
  background-color: teal;
  color: #f5f5f5;
  padding: 0 5px;
  border-radius: 5px;
}

.upcoming_anime_container button {
  padding: 10px;
  background-color: #fa37987d;
  border: 1px solid #ff007f;
  border-radius: 5px;
  color: #f5f5f5;
  font-family: 'Poppins', sans-serif;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.upcoming_anime_container button:hover {
  background-color: #ff007f;
}

@media (max-width: 575.98px) {
  .upcoming_anime_container .upcoming_anime_content {
    justify-content: flex-start;

    padding: 50px 10px 5px;
  }
  .upcoming_anime_container .upcoming_anime {
    height: 150px;
    width: 100px;
  }
}
