@import url('https://fonts.cdnfonts.com/css/poppins');

.player-container {
  display: flex;
  gap: 20px;
  padding: 80px 20px;
}

.episode {
  width: 50%;
  max-height: 600px;
  overflow-y: scroll;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}


.episode::-webkit-scrollbar {
    width: 5px; /* Height of the scrollbar */
  }

  .episode::-webkit-scrollbar-track {
    background: #292929; /* Background of the scrollbar track */
  }

  .episode::-webkit-scrollbar-thumb {
    background: #ff007f; /* Color of the scrollbar handle */
    border-radius: 10px; /* Round the corners of the scrollbar handle */
  }

  .episode::-webkit-scrollbar-thumb:hover {
    background: #555; /* Darker color on hover */
  }

  .custom-media-player {
    height: 200px;
  }

.episode div {
  background-color: #1b1b1b;
  padding: 10px;
  width: 100%;
  background-position: center;
  background-size: cover;
  border-radius: 10px; /* Add border-radius for a smoother look */
  transition: transform 0.3s ease; /* Smooth transition for hover effect */
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
}

.episode div:hover {
  transform: scale(1.05); /* Slight zoom on hover */
}

.active-episode {
  color: #ff007f;
  border: 3px solid #ff007f; /* Highlight the active episode with a border */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Add a shadow to the active episode */
}
.custom-media-player {
  width: 100%;
  max-width: 1200px; /* Set a max width for the player */
  height: 600px; /* Set the height for the player */
}

@media (max-width: 575.98px) {
  .player-container {
    flex-direction: column;
    padding: 80px 20px 40px;
    height: 80vh;
  }

  .episode {
    width: 100%;
    max-height: 300px;
    overflow-y: scroll;
    border: 1px solid #f5f5f553;
    padding: 10px;
    border-radius: 8px;
  }

  .episode::-webkit-scrollbar {
    width: 0px; /* Height of the scrollbar */
  }

  .episode::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background of the scrollbar track */
  }

  .episode::-webkit-scrollbar-thumb {
    background: #888; /* Color of the scrollbar handle */
    border-radius: 10px; /* Round the corners of the scrollbar handle */
  }

  .episode::-webkit-scrollbar-thumb:hover {
    background: #555; /* Darker color on hover */
  }

  .custom-media-player {
    height: 200px;
  }
}
