.embla {
  max-width: 110rem;
  margin: auto;
  --slide-height: 30rem;
  --slide-spacing: 1rem;
  --slide-size: 100%;
}
.embla__viewport {
  overflow: hidden;
}
.embla__container {
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
}
.embla__slide {
  transform: translate3d(0, 0, 0);
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
  background-position: center;
  background-size: cover;
  height: 30rem;
}
.embla__slide__number {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  border-radius: 1.8rem;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--slide-height);
  user-select: none;
}
.embla__controls {
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: space-between;
  gap: 1.2rem;
  margin-top: 0.5rem;
}
.embla__dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin-left: 3rem;
}
.embla__dot {
  -webkit-tap-highlight-color: #292929;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0 5px;
  width: 0.6rem;
  height: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.embla__dot:after {
  box-shadow: inset 0 0 0 1rem #292929;
  width: 0.6rem;
  height: 0.6rem;
  display: flex;
  align-items: center;
  content: '';
}
.embla__dot--selected:after {
  box-shadow: inset 0 0 0 1rem #ff007f;
}


@media (max-width: 575.98px){
  .embla__slide{
    height: 20rem;
  }

  .embla__dot{
    height: 0.3rem;
    width: 0.5rem;
  }

  .embla__dot::after{
    height: 0.3rem;
    width: 0.5rem;
  }

}