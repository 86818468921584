@import url('https://fonts.cdnfonts.com/css/poppins');

.recent_container .image_container .image {
  height: 300px;
  width: 200px;
  background-position: center;
  background-size: cover;
  border-radius: 5px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.recent_container .image_container .image .episode_container {
  padding: 5px;
  background-color: #ff007f;
  width: fit-content;
  height: fit-content;
  border-radius: 3px;
}
.recent_container .image_container .image .episode_container p {
  font-family: 'Poppins', sans-serif;
}

.recent_container .image_container .top-image{
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 30px 10px;
  font-family: 'Poppins', sans-serif;
}

.recent {
  -webkit-overflow-scrolling: touch;
  flex-direction: column;
}

.recent_arrow {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding: 0 20px;
}

.top_airing{
    justify-content: space-between;
}

.recent_arrow .icon {
  font-size: 20pt;
  cursor: pointer;
}

.recent_arrow p{
    font-family: 'Poppins', sans-serif;

}

.recent_scroll::-webkit-scrollbar {
  height: 0px; /* Height of the scrollbar */
}

.recent_scroll::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the scrollbar track */
}

.recent_scroll::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar handle */
  border-radius: 10px; /* Round the corners of the scrollbar handle */
}

.recent_scroll::-webkit-scrollbar-thumb:hover {
  background: #555; /* Darker color on hover */
}

@media (max-width: 575.98px) {
  .recent_container .image_container .image {
    height: 150px;
    width: 100px;
  }

  .recent_container .image_container .image .episode_container p{
    font-size: 8pt;
  }

  .recent_container .image_container .top-image{
    padding: 20px 10px;
  }
}
