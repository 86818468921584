*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body{
  background-color: #1f1f1f;
  color: whitesmoke;
  margin: 0 auto;
  overflow-x: hidden;
  border-left: 1px solid #f5f5f535;
  border-right: 1px solid #f5f5f535;
  position: relative;
  height: 100vh;
  max-width: 1680px;
}

body::-webkit-scrollbar {
  width: 5px;              /* Height of the scrollbar */
}

body::-webkit-scrollbar-track {
  background: #292929;      /* Background of the scrollbar track */
}

body::-webkit-scrollbar-thumb {
  background: #ff008080;         /* Color of the scrollbar handle */
  border-radius: 10px;      /* Round the corners of the scrollbar handle */
}

body::-webkit-scrollbar-thumb:hover {
  background: #ff007f;         /* Darker color on hover */
}