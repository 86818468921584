.footer-container {
    display: flex;
    align-items: center;
    width: 100%;
    background-image: linear-gradient(
        to right,
        rgba(40, 43, 48, 0.9) 100%,
        rgba(40, 43, 48, 0) 100%
      ),
      url("./bg.jpg");
    background-position: center;
    background-size: cover;
  
    .brand {
      display: flex;
      gap: 20px;
  
      .brand-info {
        display: flex;
        align-items: center;
        gap: 20px;
        .icon {
          color: #fa4863;
        }
        h1 {
          font-size: 13pt;
          font-family: "Poppins", sans-serif;
        }
        a {
          color: lightgray;
          font-family: "Poppins", sans-serif;
          font-size: 8pt;
  
          &:hover {
            color: #fff;
          }
        }
      }
  
      .brand-footer {
        display: flex;
        h3 {
          font-family: "Poppins", sans-serif;
          font-weight: 400;
          font-size: 9pt;
          color: lightgray;
        }
  
        p {
          font-family: "Poppins", sans-serif;
          font-weight: 400;
          font-size: 8pt;
          color: lightgray;
        }
      }
    }
  
    .social {
      display: flex;
      align-items: center;
      gap: 20px;
  
      a {
        font-size: 15pt;
        color: lightgray;
  
        .discord {
          &:hover {
            color: #7289da;
          }
        }
  
        .whatsapp {
          &:hover {
            color: #25d366;
          }
        }
      }
    }
  }
  
  /* Extra Large Monitors */
  @media (min-width: 992px) {
    .footer-container {
      padding: 20px 40px;
      justify-content: space-between;
  
      .brand {
        flex-direction: column;
        
        .brand-footer{
          flex-direction: column;
        }
      }
    }
  }
  
  /* Desktop Styles */
  @media (min-width: 576px) {
    .footer-container {
      padding: 15px 50px;
      justify-content: space-between;
      .brand {
        flex-direction: column;
  
        
        .brand-footer{
          flex-direction: column;
        }
      }
    }
  }
  
  /* Mobile Styles */
  @media (max-width: 575px) {
    .footer-container {
      padding: 10px 5px;
      flex-direction: column;
      .brand {
        flex-direction: column;
        align-items: center;
  
        .brand-footer{
          align-items: center;
          flex-direction: column;
  
          p{
            text-align: center;
          }
        }
      }
      gap: 20px;
    }
  }