@import url('https://fonts.cdnfonts.com/css/beyond-sky');
@import url('https://fonts.cdnfonts.com/css/lexend');
@import url('https://fonts.cdnfonts.com/css/whois');

.header_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 50px;
  background: rgba(255, 255, 255, 0.06);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(5px);
  position: absolute;
  width: 100%;
  z-index: 1;
}

.logo_container p {
  font-family: 'Beyond Sky', sans-serif;
  font-size: 35pt;
  color: #ff007f;
}

.beta {
  font-size: 20pt;
}

.input_container input {
  padding: 8px 10px;
  width: 200%;
  outline: 0.5px solid grey;
  background-color: #292929;
  border-style: none;
  color: #ff007f;
}

.nav_container {
  display: flex;
  gap: 50px;
  z-index: 6
}

.nav_container .a {
  text-decoration: none;
  color: whitesmoke;
  font-family: 'Whois', sans-serif;
  font-weight: 300;
  font-size: 10pt;
  letter-spacing: 1px;
  transition: all 0.2s ease-in-out;
  height: 25px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.nav_container .active {
  text-decoration: none;
  color: #ff007f;
  font-family: 'Whois', sans-serif;
  font-weight: 300;
  font-size: 10pt;
  letter-spacing: 1px;
  transition: all 0.2s ease-in-out;
  border-bottom: 1px solid #ff007f;
  height: 25px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.nav_container .active .icon {
  color: #ff007f;
}

.nav_container .icon {
  color: #fff;
}

.burger_container {
  display: none;
}

.input_container_results {
  width: auto;
  max-height: 500px;
  overflow-y: scroll;
}

.input_container_results::-webkit-scrollbar {
  width: 5px; /* Height of the scrollbar */
}

.input_container_results::-webkit-scrollbar-track {
  background: #292929; /* Background of the scrollbar track */
}

.input_container_results::-webkit-scrollbar-thumb {
  background: #ff008080; /* Color of the scrollbar handle */
  border-radius: 10px; /* Round the corners of the scrollbar handle */
}

.input_container_results::-webkit-scrollbar-thumb:hover {
  background: #ff007f; /* Darker color on hover */
}

@media (max-width: 575.98px) {
  .header_container {
    padding: 10px 15px;
    align-items: center;
  }
  .logo_container p {
    font-size: 25pt;
  }

  .beta {
    font-size: 12pt;
  }

  .input_container input {
    width: 100%;
  }

  .nav_container {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 100%;
    right: 0;
    transition: all 0.2s ease-in-out;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 10px;
    gap: 20px;
    z-index: 12;
  }


  .desktop {
    display: none;
  }

  .burger_container {
    display: flex;
    align-items: center;
  }

  .burger_container .icon {
    font-size: 20pt;
    color: grey;
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .header_container {
    padding: 10px 15px;
    align-items: center;
  }
  .logo_container p {
    font-size: 25pt;
  }

  .input_container input {
    width: 100%;
  }

  .nav_container {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 100%;
    left: 5%;
    transition: all 0.2s ease-in-out;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(19px);
    -webkit-backdrop-filter: blur(19px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 10px;
    gap: 20px;
  }

  .desktop {
    display: none;
  }

  .burger_container {
    display: flex;
    align-items: center;
  }

  .burger_container .icon {
    font-size: 20pt;
    color: grey;
  }
}
