@import url('https://fonts.cdnfonts.com/css/poppins');

.input_container_results{
    position: absolute;
    padding: 10px;
    background-color: #292929;
    min-width: 370px;
    margin: 0 auto;
    top: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.result_item{
    display: flex;
    gap: 10px;
    cursor: pointer;
}

.result_item .result_item_image{
    height: 70px;
    width: 50px;
    border-radius: 5px;
    background-position: center;
    background-size: cover;
}

.result_item_info{
    height: 70px;
    display: flex;
    flex-direction: column;
}

.result_item .result_item_info p{
    font-family: 'Poppins', sans-serif;
}

.result_item .result_item_info .status{
    color: #ff007f;
    font-size: 8pt;
    font-weight: 200;
}

@media (max-width: 575.98px){
    .input_container_results{
        width: 100%;
        left: 0;
    }
}